import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { Routes } from 'react-router-dom/dist';
import PageErrror from 'components/Error/PageErrror';
import BasicComponent from 'pages/basic.component';
import CustomerFormComponent from 'components/Forms/CustomerFormComponent';
import { getLangOptions } from 'constants/langOptions';

/**
 * The Anonymous Routes.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Anonymous() {
    const location = useLocation();
    let mylang = localStorage.getItem("i18nextLng").substring(0, 2);
    if (mylang === null || mylang === undefined) {
        mylang = "en";
        localStorage.setItem("i18nextLng", "en");
    }
    let theme = localStorage.getItem("theme");
    if (theme !== undefined && theme !== "light" && theme !== "dark" && theme !== "") {
        localStorage.setItem("theme", "light");
    }

    // Check if the path starts with /en/ or /gr/
    const path = location.pathname;
    if (!path.startsWith("/en") && !path.startsWith("/el")) {
        // If the path doesn't start with /en/ or /gr/, redirect to the same path with /en/ in front
        // localStorage.setItem("i18nextLng", "en");
        return <Navigate to={`/${mylang}${path}${location.search}`} replace />;
    }

    return (
        <Routes>
            {getLangOptions().map((lang, i) => (
                <>
                    <Route key={`customer-form-${lang}`} caseSensitive path={`/${lang}/customer-form`} element={<CustomerFormComponent />} />
                    <Route key="redirect" path={`/${lang}/:path`} element={<BasicComponent />} />
                </>
            ))}

            <Route key="error" caseSensitive path={"/error"} element={<PageErrror />} />
            <Route key="empty" caseSensitive path="*" element={<PageErrror />} />
        </Routes>
    )
}

export default Anonymous;