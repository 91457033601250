const EL = {
  errorPages: {
    somethingWentWrong: 'Ουπς! Κάτι πήγε στραβά. Παρακαλούμε προσπαθήστε ξανά.',
  },
  contactForm: {
    customerDetails: "Στοιχεία Πελάτη",
    outletName: "Όνομα Πελάτη",
    phoneNumber: "Αριθμός Τηλεφώνου",
    email: "Email",
    submit: "Ολοκλήρωση",
    thankU: "Σας ευχαριστούμε για το ενδιαφέρον σας",
    contactSoonNote: "Θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό"
  },
  languages: {
    en: "Αγγλικά",
    de: "Γερμανικά",
    fr: "Γαλλικά",
    it: "Ιταλικά",
    ru: "Ρώσικα",
    el: "Ελληνικά",
    uk: "Ουκρανικά",
    es: "Ισπανικά",
    nl: "Ολλανδικά",
    cs: "Τσέχικα",
    pl: "Πολωνικά",
    bg: "Βουλγάρικα",
    ar: "Αραβικά",
    et: "Εσθονικά",
    lt: "Λιθουάνικα",
    ro: "Ρουμάνικα",
    tr: "Τούρκικα"
  }
}

export default EL;
