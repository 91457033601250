const EN = {
  errorPages: {
    somethingWentWrong: 'Oops! Looks like you\'re lost in space. Please try again.',
  },
  contactForm: {
    customerDetails: "Customer Details",
    outletName: "Outlet Name",
    phoneNumber: "Phone Number",
    email: "Email",
    submit: "Submit",
    thankU: "Thank you for your interest",
    contactSoonNote: "We will contact you as soon as possible"
  },
  languages: {
    en: "English",
    de: "German",
    fr: "French",
    it: "Italian",
    ru: "Russian",
    el: "Greek",
    uk: "Ukrainian",
    es: "Spanish",
    nl: "Dutch",
    cs: "Czech",
    pl: "Polish",
    bg: "Bulgarian",
    ar: "Arabic",
    et: "Estonian",
    lt: "Lithuanian",
    ro: "Romanian",
    tr: "Turkish"
  }
}

export default EN;
