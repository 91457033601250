import React, { useState } from 'react';
import { Alert, Backdrop, Box, Button, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import logo from 'assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from 'utils/useDocumentTitle';
import LanguageSelector from 'components/Language/LanguageSelector';
import customerFormApi from 'api/customerForm'; 


const CenteredForm = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
    padding: '2rem',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
});

const Logo = styled(Box)({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#e6eded',
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    color: '#fff',
});

const alertTypes = {
    SUCCESS: "success",
    ERROR: "error"
}

function CustomerFormComponent() {
    console.log("customer-form")
    const { t } = useTranslation();
    //change document title
    useDocumentTitle(`Usee | ${t('login')}`);

    const [formData, setFormData] = useState({ outletName: '', phoneNumber: '', email: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Snackbar states
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    // eslint-disable-next-line
    const [alertTime, setAlertTime] = useState(2000);
    const [alertSeverity, setAlertSeverity] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const showAlert = (message, type, time = 2) => {
        setAlertSeverity(type);
        setAlertTime(time * 1000)
        setAlertMessage(message);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            // console.log("Submit button is pressed");
            if (formData.email === '' || formData.phoneNumber === '' || formData.outletName === '') {
                showAlert("Please fill all the required fields", alertTypes.ERROR);
                return;
            }
            const response = await customerFormApi.postForm(formData);
            // console.log(response["data"]);
            if (response["data"]) {
                setIsModalOpen(true); // Show modal on success
            } else {
                showAlert('Could not save your data. Please try again', alertTypes.ERROR);
                // alert('Submission failed. Please try again.'); // Handle error case
            }
        } catch (error) {
            // console.log(error);
            showAlert('Could not save your data. Please try again', alertTypes.ERROR);
        }

    };

    return (
        <>
            <Box sx={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundColor: '#67BADB'
            }}>
                {/* Darken background when modal is open */}
                <Backdrop open={isModalOpen} sx={{ zIndex: 1, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.7)' }} />

                {/* Form container */}
                <CenteredForm component="form" onSubmit={handleSubmit} sx={{ position: 'relative' }}>
                    {/* Flag positioned at top-right corner inside the form */}
                    <Box sx={{ position: 'absolute', top: 10, right: 13 }}>
                        {/* <img
                            src={language === 'gr' ? flagUk : flagGr}
                            alt="Flag"
                            style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                            onClick={toggleLanguage}
                        /> */}
                        <LanguageSelector classVal={"anonymous"} size={"25"} hideCurrentLang={true} />
                    </Box>

                    <Logo>
                        <img alt="" src={logo}
                            style={{ width: 80, height: 80 }} />
                    </Logo>

                    <Typography variant="h5" gutterBottom>
                        {t('contactForm.customerDetails')}
                    </Typography>

                    <TextField
                        label={t('contactForm.outletName')}
                        name="outletName"
                        variant="outlined"
                        fullWidth
                        required
                        autoComplete="organization"
                        margin="normal"
                        value={formData.outletName}
                        onChange={handleChange}
                    />

                    <TextField
                        label={t('contactForm.phoneNumber')}
                        name="phoneNumber"
                        variant="outlined"
                        fullWidth
                        required
                        autoComplete="tel"
                        margin="normal"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />

                    <TextField
                        label={t('contactForm.email')}
                        name="email"
                        variant="outlined"
                        fullWidth
                        required
                        autoComplete="email"
                        margin="normal"
                        value={formData.email}
                        onChange={handleChange}
                    // onKeyUp={(e) => {
                    //     if (e.key === 'Enter') {
                    //         handleSubmit(e);
                    //     }
                    // }}
                    />

                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}
                        style={{ textTransform: 'none' }}>
                        {t('contactForm.email')}
                    </Button>
                </CenteredForm>

                {/* Success Modal */}
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="modal-title">
                    <Box sx={{
                        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper', p: 3, borderRadius: '8px', boxShadow: 24,
                    }}>
                        <Typography id="modal-title" variant="h6" component="h2" align="center" gutterBottom>
                            {t('contactForm.thankU')}
                        </Typography>
                        <Typography align="center">
                            {t('contactForm.contactSoonNote')}
                        </Typography>
                    </Box>
                </Modal>
            </Box>

            {/*Alerts*/}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={alertMessage}
            >
                <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

        </>
    );
}

export default CustomerFormComponent;
