import React from "react";
import { useTranslation } from "react-i18next";
import errorPage from 'assets/images/error-page.png';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Error Page.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Page404() {
    const { t } = useTranslation();

    //change document title
    useDocumentTitle('Usee | Error')

    return (
        <div className="container">
            <img src={errorPage} alt="Error" className="image" />
            <div className="text-container">
                <h1 className="text">{t('errorPages.somethingWentWrong')}</h1>
            </div>
        </div>
    );
}

export default Page404;