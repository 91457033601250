import { request } from 'constants/alias';
import { API } from "constants/config";


/* The rest request which attempts to post Customer Form.
 * @param {*} form
 *
 * @returns an object refering to the success or failure of the request
 */
export async function postForm(form) {
    return await request.post(`${API}customer-form`, form, {
            headers: {
                'Content-Type': 'application/json', // Ensure the content type is JSON
            }
        }
    );
}

const customerFormApi = {
    postForm
}

export default customerFormApi;