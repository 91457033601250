import React, { useEffect } from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import qrcodePathApi from "api/qrcodepath";
import { useLocation, useNavigate, useParams } from "react-router";
import { Box, CircularProgress } from "@mui/material";

/**
 * The TestComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function BasicComponent() {
    //change document title
    const { path } = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();

    useDocumentTitle(`Usee | Test`);

    useEffect(() => {
        fetchRedirectURL();
        // eslint-disable-next-line
    }, []);

    /**
     * Function that triggers the modification of the outlet
     */
    function fetchRedirectURL() {
        qrcodePathApi.getRedirectURL(path + search).then((r) => {
            if (r.data.code === "SUCCESS")
                window.location = r.data.returnobject;
            else
                navigate("/error")
        }).catch((e) => {
            navigate("/error")
        });
    }

    return (
        <Box sx={{ position: "absolute", left: "50%", bottom: "50%" }}>
            <CircularProgress />
        </Box>
    );
}

export default BasicComponent;